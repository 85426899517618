html {
    box-sizing: border-box;
}

*, *::before, *::after { // stylelint-disable selector-max-universal
    box-sizing: inherit;
    text-decoration: none;
}

html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background-color: #ffffff;
    color: #111111;
}
