html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

li {
  list-style-type: none;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  text-decoration: none;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  background-color: #ffffff;
  color: #111111;
}

.aux-lockdown {
  max-width: 450px;
  margin: 40px auto;
  border-radius: 2px;
  margin-top: 20vh;
}
.aux-lockdown .aux-lockdown--wrap {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.aux-lockdown .aux-lockdown--wrap-outer {
  display: table-cell;
  vertical-align: top;
  padding: 20px;
  padding-right: 10px;
}
.aux-lockdown .aux-lockdown--wrap-outer ~ .aux-lockdown--wrap-outer {
  padding-left: 10px;
}
.aux-lockdown .aux-lockdown--logosection {
  width: 30%;
  text-align: center;
}
.aux-lockdown .aux-lockdown--logosection a {
  display: block;
}
.aux-lockdown .aux-lockdown--logosection img {
  max-width: 100%;
}
.aux-lockdown .aux-lockdown--logo {
  height: 83px;
}
.aux-lockdown .aux-lockdown--content {
  width: 70%;
}
.aux-lockdown .aux-lockdown--title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 16px;
}
.aux-lockdown .aux-lockdown--description {
  line-height: 1.5;
  margin-bottom: 16px;
}
.aux-lockdown .aux-lockdown--field {
  position: relative;
}
.aux-lockdown label {
  display: none;
}
.aux-lockdown input {
  display: inline-block;
  padding: 11px 24px 11px 0;
  font-size: 16px;
  border: none;
  outline: none;
  appearance: none;
  transition: border 200ms ease, background-color 200ms ease;
}
.aux-lockdown input:focus, .aux-lockdown input:hover {
  border: none;
}
.aux-lockdown input[type=text],
.aux-lockdown input[type=password] {
  border-bottom: 2px solid #111111;
  border-radius: 0;
  width: 100%;
}
.aux-lockdown .aux-lockdown--submit,
.aux-lockdown button[type=submit] {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  width: 17px;
  height: 15px;
  transform: translateY(-50%);
}
.aux-lockdown .aux-lockdown--submit:focus .arrow, .aux-lockdown .aux-lockdown--submit:hover .arrow,
.aux-lockdown button[type=submit]:focus .arrow,
.aux-lockdown button[type=submit]:hover .arrow {
  visibility: hidden;
}
.aux-lockdown .aux-lockdown--submit:focus .arrow.arrow__hover, .aux-lockdown .aux-lockdown--submit:hover .arrow.arrow__hover,
.aux-lockdown button[type=submit]:focus .arrow.arrow__hover,
.aux-lockdown button[type=submit]:hover .arrow.arrow__hover {
  visibility: visible;
}
.aux-lockdown ul.errorlist {
  margin-top: 12px;
  color: #dc0046;
  text-align: left;
}
.aux-lockdown .arrow {
  visibility: visible;
  position: absolute;
  left: 0;
  top: 0;
}
.aux-lockdown .arrow.arrow__hover {
  visibility: hidden;
}

@media (max-width: 767px) {
  .aux-lockdown {
    text-align: center;
    padding: 0 30px;
    margin-top: 20vh;
  }
  .aux-lockdown .aux-lockdown--wrap {
    display: block;
  }
  .aux-lockdown .aux-lockdown--wrap-outer {
    display: block;
    width: 100%;
  }
  .aux-lockdown .aux-lockdown--logosection {
    padding: 0 0 15px;
  }
  .aux-lockdown .aux-lockdown--logo {
    width: 60px;
    height: 60px;
  }
  .aux-lockdown .aux-lockdown--description {
    margin-bottom: 50px;
  }
}